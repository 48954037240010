//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React from 'react'
import * as Cirrus from "manpowergroup-cirrus";
import { useEffect, useState } from 'react';
import Header from '../../../pages/header/simpleHeader';
import htmlParser from 'html-react-parser';
import Footer from '.';
import { FooterActions } from './FooterActions';
import Spinner from '../spinner-loader/Spinner';
import './footer.css'

const PolicyPrivacy = () => {
    const [data, setData] = useState();
    const [screenLoader, setScreenLoader] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        getPolicyData()
    }, [])

    let auth = {
        'token': sessionStorage.getItem("setIdTokenClaims") ? true : false
    }

    const getPolicyData = async () => {
        await FooterActions.GetCookieNPolicies({
            "pagename": "PrivacyPolicy"
        }).then((res) => {
            if (res.status == 200) {
                setData(res?.data?.Pages[0])
                setScreenLoader(false)
            } else {
                setScreenLoader(false);
            }
        }, (err) => {
            console.log(err);
            setScreenLoader(false);
        })
    }

    return (
        <>
            <Cirrus.Page
                background='secondary'
                backgroundShape='singleDesktop'
                header={<Header mode="back" />}
                className="ourPolicy privacy-policy"
            >
                {
                    screenLoader === true ? <div>
                        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
                    </div> : <Cirrus.Container heading={data?.Title} className="privacy-content">
                        {htmlParser(data ? data?.Description : "")}
                    </Cirrus.Container>
                }
            </Cirrus.Page>
            {
                auth.token ? <Footer isLoggedIn={true} /> : <Footer isLoggedIn={false} />
            }
        </>
    )
}

export default PolicyPrivacy
